import isEmpty from "lodash/isEmpty";
import PropTypes from "prop-types";
import React from "react";

import ApiClient from "@/api/client/client";
import { SESSION_STORAGE_KEYS } from "@/constants";
import { createItem, getItem } from "@/utils/helpers/localStorage";

export const UserContext = React.createContext();

class User extends React.Component {
  state = {
    error: false,
    initialized: false,
    loading: false,
    session: {},
    token: "",
    userLocation: {},
  };

  async getCurrentSession() {
    const session = await ApiClient.currentSession()
      .then((sessionData) => {
        return sessionData;
      })
      .catch(() => {
        return {};
      });

    return session;
  }

  componentDidMount() {
    // Fetch the Session info from Local Storage
    const localStorageUserSession = getItem(SESSION_STORAGE_KEYS.USER_SESSION);

    // Make initial assignment
    this.setState(
      (prevState) => ({
        initialized: true,
        session:
          !prevState.session || isEmpty(prevState.session)
            ? localStorageUserSession || {}
            : prevState.session,
      }),
      () => {
        // Then retrieve the Token, if there`s a session on the API
        this.getCurrentSession()
          .then((sessionInfo) => {
            if (sessionInfo) {
              const {
                auth0Token: { id_token },
              } = sessionInfo;

              this.setState({
                token: id_token,
              });
            }
          })
          .catch(() => {});
      }
    );
  }

  updateUserSession = (userSession) => {
    createItem(SESSION_STORAGE_KEYS.USER_SESSION, userSession);

    this.setState({
      session: userSession,
    });
  };

  getDataLayerInfo = () => {
    if (this.state.session && this.state.session.user) {
      return {
        loginStatus: "authenticated",
        loginType: this.state.session.user.isProspect ? "p" : "m",
        ...(this.state.session.user.country && {
          country: this.state.session.user.country.toLowerCase(),
        }),
        facebookLink: this.state.session.user.facebookId ? "yes" : "no",
        ...(this.state.session.user.id && {
          userID: this.state.session.user.id.toString(),
        }),
        ...(this.state.session.user.ptStatus && {
          ptStatus: this.state.session.user.ptStatus,
        }),
        ...(this.state.session.user.accountStatus && {
          accountStatus: this.state.session.user.accountStatus,
        }),
        ...(this.state.session.user.hashedEmail && {
          hashedEmail: this.state.session.user.hashedEmail,
        }),
        ...(this.state.session.user.memberId && {
          memberId: this.state.session.user.memberId.toString(),
        }),
        ...(this.state.session.user.trackingId && {
          trackingId: this.state.session.user.trackingId.toString(),
        }),
      };
    }

    return {
      loginStatus: "not-authenticated",
    };
  };

  render() {
    const { children } = this.props;

    return (
      <UserContext.Provider
        value={{
          ...this.state,
          getDataLayerInfo: this.getDataLayerInfo,
          updateUserSession: this.updateUserSession,
        }}
      >
        {children}
      </UserContext.Provider>
    );
  }
}

User.propTypes = {
  children: PropTypes.node,
};

export default User;

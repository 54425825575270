export const FREEZE = {
  REASONS: {
    MEDICAL: "Medical",
    PREGNANCY: "Pregnancy",
    REGULAR: "Regular",
  },
  SOURCES: {
    "Club App": "Club App",
    Engage: "Engage",
    clubapp: "Club App",
    concierge: "Engage",
    web_: "Manage account",
  },
};

export const STATUSES = {
  ACTIVE: "Active",
  CANCELLED: "Cancelled",
  DELINQUENT: "Delinquent",
  DEPLETED: "Depleted",
  EXPIRED: "Expired",
  FREEZE: "Freeze",
  HOLD: "Hold",
  INVALID: "Invalid",
  NOT_FINALIZED: "Not Finalized",
  PENDING_START: "Pending Start",
  PROCESSING: "Processing",
  TERMINATED: "Terminated",
};

export const ACQ_BILLING = "pageAcqBilling";
export const ACQ_REGISTRATION = "pageAcqRegistration";
export const AllClubClassSchedules = "AllClubClassSchedules";
export const ARTICLE_PAGE = "pageArticle";
export const ArticlesYearMonthList = "ArticlesYearMonthList";
export const BottomOfPageCta = "moduleBottomOfPageCta";
export const CLASS_CATEGORY = "componentClassCategory";
export const CLUB = "club";
export const DIGITAL_SIGNATURE = "pageDigitalSignature";
export const DIRECT_RESPONSE = "pageDirectResponsePage";
export const FURTHERMORE_ARTICLE = "publicArticle";
export const FURTHERMORE_TAG = "tag";
export const GIFT_CARD = "pageGiftCard";
export const IntentClassificationModal = "moduleIntentClassificationModal";
export const JanuaryTakeover = "JanuaryTakeover";
export const LANDING = "pageLanding";
export const MEMBERSHIP_PLAN_DETAIL = "membershipPlanDetail";
export const MembershipCancellation = "MembershipCancellation";
export const MODULE_FORM = "moduleForm";
export const MODULE_LEAD_FLOW = "moduleLeadFlow";
export const MODULE_MEMBERSHIP_OPTION = "moduleMembershipOption";
export const OpportunityLandingPage = "OpportunityLandingPage";
export const PAGE_CLUB_DETAIL = "page-club-detail-page";
export const PAGE_CUSTOM_FORM = "pageCustomForm";
export const PAGE_JOINFLOW_ALUMNI = "pageJoinFlowAlumni";
export const PAGE_SPA_BOOKING = "pageSpaBooking";
export const PAGE_TEMPLATE = "page";
export const PAY_PER_CLASS = "pagePayPerClass";
export const QUIZ_COMPONENT = "componentQuiz";
export const REFERRALS = "pageReferralsInvite";
export const REGION = "region";
export const SCHEDULE_EQUIFIT = "pagescheduleEquifit";
export const SUB_REGION = "subRegion";
export const TRIAL_PASS = "pageTrialPass";

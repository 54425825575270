import PropTypes from "prop-types";
import React from "react";

export const AccountContext = React.createContext();

class Account extends React.Component {
  state = {
    addressData: {},
    profileData: {},
  };

  updateAccountDetails = (key, data) => {
    this.setState({ [key]: data });
  };

  render() {
    const { children } = this.props;

    return (
      <AccountContext.Provider
        value={{
          ...this.state,
          updateAccountDetails: this.updateAccountDetails,
        }}
      >
        {children}
      </AccountContext.Provider>
    );
  }
}

Account.propTypes = {
  children: PropTypes.node,
};

export default Account;

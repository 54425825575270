import { AVAILABLE_USER_STATES_BY_COUNTRY } from "./states";

export const appEnvironment = process.env.NEXT_PUBLIC_ENVIRONMENT;

export const baseEnvironmentConfig = {
  analytics: {
    apiUrl: "https://somnit.equinox.com/b/ss//6",
    cookies: {
      marketingCloudVisitorID: "s_ecid",
    },
    marketingcloudorgid: "8E4567C25245B0B30A490D4C@AdobeOrg",
    pageName: "login:webview",
    pageURL: "login.equinox.com",
    reportSuiteID: "eqeqxdpwdev", // overridden for production in config.js
  },
  app_host: process.env.NEXT_PUBLIC_APP_HOST,
  available_states: AVAILABLE_USER_STATES_BY_COUNTRY,
  equinox_api: {
    host: process.env.NEXT_PUBLIC_EQUINOX_API_HOST,
    v6_api_host: process.env.NEXT_PUBLIC_EQUINOX_API_HOST,
  },
  equinox_bav_api: {
    host: process.env.NEXT_PUBLIC_EQUINOX_API_HOST + "/v6/ancillary/bav",
  },
  equinox_gateway: {
    host: process.env.EQUINOX_GATEWAY_HOST,
    token: process.env.EQUINOX_GATEWAY_TOKEN,
  },
  localStorageItems: {
    classScheduleFilters: "classScheduleFilters",
    giftCardPayload: "giftCardPayload",
    hamptonsBookingClass: "hamptonConfirmation",
    loginRedirectUrl: "loginRedirectUrl",
    // hamptonsBookingClassPayload: "hamptonBookingClassPayload",
  },
  pageNames: {
    acqBilling: "acq-billing",
    brand: "brand",
    ccpa: "ccpa",
    classSchedule: "class-schedule",
    classScheduleLanding: "groupfitness/classes",
    clubDetail: "club-detail",
    contract: "contract",
    corpJoin: "corpJoin",
    equifitQuestionnaire: "equifit-questionnaire",
    feedback: "feedback",
    findLocation: "find-location",
    formerMember: "formerMember",
    gfCategoryScheduleLanding: "groupfitness/{0}/classes",
    gfClassCategory: "group-fitness-class-category",
    gfClassCategoryLanding: "GroupFitnessClassCategoryPage",
    gfClassInstance: "group-fitness-class-instance",
    gfClassScheduleLanding: "groupfitness/{0}/{1}/classes",
    gfClubCategoryClassScheduleLanding:
      "clubs/{0}/{1}/{2}/groupfitness/{3}/classes",
    gfSignatureClass: "GroupFitnessSignatureClassPage",
    groupFitness: "group-fitness",
    groupFitnessLanding: "groupFitnessLanding",
    hamptonsLanding: "classschedule/hamptons",
    home: "home",
    landing: "landing",
    memberBenefits: "member-benefits",
    memberBenefitsLanding: "member_benefits",
    pilates: "pilates",
    promotion: "promotion",
    prospect: "prospect",
    pt: "personal-training",
    reactivate: "reactivate",
    referrals: "referrals",
    remoteClose: "remoteclose",
    shop: "shop",
    spa: "spa",
    spaBooking: "spaBooking",
    tierXQuestionnaire: "tierXQuestionnaire",
  },
  pages: {
    accountFreeze: {
      allowSearchEngineIndex: false,
      description: "Equinox Freeze",
      title: "Equinox Freeze",
    },
  },
  session: {
    cookies: {
      legacy: "EqAuth.v1",
      login: "EqxWeb.Login",
      paymentAppHampton: "hamptonToken",
      paymentAppProductToken: "productToken",
      paymentAppRedirectTo: "redirect",
      paymentAppReferrer: "referrer",
      paymentAppSource: "source",
    },
    defaultLoginUrl: "/account",
  },
  testIds: {
    accordion: "accordion",
    autoPlayVideo: "auto-play-video",
    basicNav: "basic-nav",
    datePicker: "date-picker",
    emailVerificationConfirmation: "email-verification-confirmation",
    emailVerificationError: "email-verification-error",
    emailVerificationLanding: "email-verification-landing",
    emailVerificationThankyou: "email-verification-thankyou",
    employeeForm: "employee-form",
    joinFlowCta: "join-flow-cta",
    leadFlow: "lead-flow",
    overlappedColumns: "overlapped-columns",
    primaryCta: "primary-cta",
    successScreen: "success-screen",
  },
};

export default baseEnvironmentConfig;

import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

import ChatbotSvgIcon from "@/assets/icons/chatbot.svg";

import styles from "./NetomiChatbot.module.scss";

const NetomiChatbot = () => {
  const router = useRouter();
  const [showChatbot, setShowChatbot] = useState(false);

  useEffect(() => {
    const showChatbotURLs =
      [
        "/",
        "/clubs/[region]/[subregion]",
        "/clubs/[region]/[subregion]/[clubName]",
      ].includes(router.pathname) ||
      ["/faq", "/memberbenefits"].includes(router.asPath?.split("?")[0]);

    const initNetomiChatbot = async () => {
      const netomiChatWindow = document.getElementById("netomiChatWindow");

      if (!showChatbotURLs) {
        if (window?.NETOMI_WEB_WIDGET) {
          netomiChatWindow?.classList.remove("show");
        }

        setShowChatbot(false);

        return;
      }

      if (window?.NETOMI_WEB_WIDGET && netomiChatWindow) {
        window.Netomi.chat.subscribe("MINIMIZE_CHAT_WIDGET", () => {
          netomiChatWindow.classList.remove("show");
        });

        window.Netomi.chat.subscribe("CLOSE_CHAT_WIDGET ", () => {
          netomiChatWindow.classList.remove("show");
        });

        setShowChatbot(true);

        return;
      }

      setTimeout(initNetomiChatbot, 100);
    };

    initNetomiChatbot();
  }, [router.asPath, router.pathname]);

  const launchNetomiChatbot = () => {
    window.NETOMI_WEB_WIDGET.publish("OPEN_WIDGET");
    document.getElementById("netomiChatWindow").classList.add("show");
  };

  if (!showChatbot) {
    return null;
  }

  return (
    <div className={styles.chatbotContainer} onClick={launchNetomiChatbot}>
      <ChatbotSvgIcon />
    </div>
  );
};

export default NetomiChatbot;

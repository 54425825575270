const CONFIRMATION_TOKEN = {
  name: "confirmationToken",
};

const JOIN_TODAY = {
  name: "joinToday",
};

const NEARBY_FACILITES = {
  expiration: 86400, // in seconds = 1 day
  name: "EqxWeb.FacilityIDs",
};

const PAYMENT_JOIN_PRODUCT_TOKEN = {
  name: "joinToken",
};

const SELECTED_CLUB_ID = {
  name: "selectedClubId",
};

const USER_INFO = {
  name: "userInfo",
};

const USER_LOCATION = {
  expiration: 1800, // in seconds = 30 minutes
  name: "EqxWeb.Location",
};

const USER_SESSION_ACCESS_TOKEN = {
  name: "EqxWeb.bavToken",
  options: {
    domain:
      process.env.NEXT_PUBLIC_ENVIRONMENT === "localhost"
        ? "localhost"
        : "equinox.com",
    path: "/",
    secure:
      process.env.NEXT_PUBLIC_ENVIRONMENT !== "localhost" ||
      process.env.USE_LOCAL_SSL === "true",
  },
};

module.exports = {
  CONFIRMATION_TOKEN,
  JOIN_TODAY,
  NEARBY_FACILITES,
  PAYMENT_JOIN_PRODUCT_TOKEN,
  SELECTED_CLUB_ID,
  USER_INFO,
  USER_LOCATION,
  USER_SESSION_ACCESS_TOKEN,
};
